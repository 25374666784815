import "./contact.css"
import GoogleMapReact from 'google-map-react';
import pinIcon from "../resources/images/pin.png"
import phoneIcon from "../resources/images/phone.png"
import emailIcon from "../resources/images/email.png"

function Contact() {
    return (
        <div id="contactContainer">
            <ContactData/>
            <Map/>
        </div>
    )
}

function Map() {

    const Marker = props => {
        return <>
          <div className="pin"></div>
        </>
      }

    const defaultProps = {
        center: {
          lat: 51.1201698,
          lng: 17.0291515
        },
        zoom: 18
      };

    return (
        <div id = "map" style={{ height: '75vh', width: '50vw' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBo_VJII7V_RsGgVUfQ7_c6c8I1YznQyxA" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}>
                <Marker
                   lat={51.1201698}
                   lng={17.0291515}>
                </Marker>
            </GoogleMapReact>
        </div>
    )
}

function ContactData() {
    return (
        <div id="contactData">
            <h1>Znajdź mnie tutaj</h1>

            <div id="contactRow">
                <img id="icon" src= {pinIcon} height={25} alt=""></img>
                
                <div>
                    <p>Kaszubska 8/107, 4 piętro</p>
                    <p>50-214 Wrocław</p>
                </div>
            </div>

            <div id="contactRow">
                <img id="icon" src= {phoneIcon} height={25} alt=""></img>
                <p>+48 731 659 700</p>
            </div>

            <div id="contactRow">
                <img id="icon" src= {emailIcon} height={25} alt=""></img>
                <p>h.terechowska@gmail.com</p>
            </div>

            <h1 id = "contactHoursTitle">Godziny otwarcia</h1>
            <p>Poniedziałek - Sobota: <b>10:00 - 19:00</b></p>
        </div>
        
    )
}

export default Contact;