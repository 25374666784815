import worksFirst from "../resources/images/worksFirst.jpg"
import worksSecond from "../resources/images/worksSecond.jpg"
import worksThird from "../resources/images/worksThird.jpg"
import aboutImage from "../resources/images/about.jpg"
import instagram from "../resources/images/instagramWhite.png"
import star from "../resources/images/star.png"
import arrowDown from "../resources/images/arrowDown.png"
import { commentsList } from "../models/commentsData.js"
import "./home.css"
import "./about.css"
import "./works.css"
import "./comments.css"
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import React from 'react'
import { useMediaQuery } from "react-responsive";

function Home() {
    return (
        <div id = "homeContainer">
            <About/>
            <Works/>
            <Comments/>
        </div>
    )
}

function Comments() {
    return (
      <div id = "commentsContainer">
        <div id = "commentsTextsContainer">
          <h1 id = "commentsTitle">Opinie klientów</h1>
          <p id = "commentsDescription">W tym miejscu możesz odkryć bogactwo opinii naszych klientów, tworząc niepowtarzalny pejzaż naszej interakcji z nimi</p>
          <div id = "commentsButtonsContainer" >
            <Link className = "mainButton" to = "https://www.google.pl/maps/place/HONEY/@51.1201698,17.0265766,17z/data=!4m8!3m7!1s0x470fe90df092d889:0x244ee4b871fdc911!8m2!3d51.1201698!4d17.0291515!9m1!1b1!16s%2Fg%2F11vwjgrnb9?entry=ttu" >Google</Link>
          </div>
        </div>
        <CommentsCarousel/>
      </div>
    )
}

function CommentsCarousel() {
  const isMobile = useMediaQuery({ maxWidth: "768px" });

  const slider = React.useRef(null);

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
    };

    let comments = []

    for(let i = 0; i < commentsList.length; i++) {
      comments.push(<CommentItem data = { commentsList[i] } />)
    }

    return (
      <div id = "carouselContainer"> 
        <button id = "carouselLeftButton" onClick={() => slider?.current?.slickPrev()}>
          <img src = { arrowDown } height = { 30 } alt = ""/>
        </button>

        <Slider id = "commentsCarousel" ref={slider} {...settings} style = {{ width: isMobile ? "300px" : "400px", margin: "30px"}}>
          { comments }

        </Slider>
        <button id = "carouselRightButton" onClick={() => slider?.current?.slickNext()}>
          <img src = { arrowDown } height = { 30 } alt = ""/>
        </button>
      </div>
    )
}

function CommentItem(props: Props) {
    let stars = []

    for (let i = 0; i < 5; i++) {
      stars.push(<img src = { star } height = { 30 } alt = ""/>)
    }

    return (
      <div id = "commentItemContainer">
          <div id = "commentStars">
            { stars }
          </div>
          <h4 id = "commentTitle"> { props.data }</h4>
      </div>
    )
}

function Works() {
    return (
        <div id = "worksContainer">
            <div id="worksTextContainer">
                <h1 id = "worksTitle">Nasze prace</h1>
                <p id = "worksDescription">Odkryj nasze Instagramowe konto, gdzie przezentujemy nasze osiągnięcia w świecie kosmetologii, inspirując Cię do odkrywania piękna w sobie!</p>
                <Link  to="https://www.instagram.com/hanna_terechowska?igsh=dXQxMjU0NG55azVo">
                    <div className = "mainButton" id = "worksButton">
                        <img id = "worksIcon" src = { instagram } height = { 30 } alt = ""/>
                        <p id = "worksButtonTitle">Instagram</p>
                    </div>
                </Link>
            </div>

            <div id = "worksImagesHorizontalContainer"> 
                <div id = "worksImagesVerticalContainer">
                    <img className = "image" id = "worksImageSquare" src = { worksFirst } alt = ""/>
                    <img className = "image" id = "worksImageSquare" src = { worksSecond } alt = ""/>
                </div>

                <img className = "image" id = "worksImageRectangle" src = { worksThird } alt = ""/>
            </div>
        </div>
    )
}

function About() {
    return (
        <div id="aboutContainer">
            <div id = "aboutTextContainer">
                <h1 id = "aboutTitle">Witamy w strefie piekna</h1>
                <p id = "aboutDescriptionFirstLine"><b>HONEY</b> to oaza relaksu i urody, gdzie profesjonalna pielęgnacja spotyka się z przyjazną atmosferą.
                Nasza oferta obejmuje kompleksowe usługi kosmetyczne, dostosowane do indywidualnych potrzeb każdego klienta.</p>
                <p id = "aboutDescriptionSecondLine"><u>Twoje piękno - nasz priorytet</u></p>
                <Link className = "mainButton" to="https://booksy.com/pl-pl/232551_honey_salon-kosmetyczny_13750_wroclaw">
                    Zarezerwuj wizytę
                </Link>
            </div>
            
            <img className = "image" id = "aboutImage" src = { aboutImage } alt = ""/>
        </div>
    )
}

export default Home;