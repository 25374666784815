import { NavLink } from "react-router-dom";
import "./navBar.css"
import { Link } from 'react-router-dom'
import instagramLogo from "../resources/images/instagramWhiteFilled.png"
import googlePinLogo from "../resources/images/mapPinWhite.png"
import { IoClose, IoMenu } from "react-icons/io5";
import { useState } from 'react'
import { useMediaQuery } from "react-responsive";

function NavBar() {
    const [showMenu, setShowMenu] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: "768px" });

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <header>
            <nav className="navigationContainer">

                <div id="logo">
                    <NavLink to="/" className="logo"> HONEY </NavLink>
                </div>

                { !isMobile && (
                    <div id="menu">
                        <ul>
                            <li>
                                <NavLink to="/" className="link">O nas</NavLink>
                            </li>
                            <li>
                                <NavLink to="/price" className="link">Cennik</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact" className="link">Kontakt</NavLink>
                            </li>
                        </ul>
                    </div>
                )}
               
                { !isMobile && (
                    <div className="socials" id="socials">
                        <ul>
                            <li>
                                <Link to="https://booksy.com/pl-pl/232551_honey_salon-kosmetyczny_13750_wroclaw">
                                    Zarezerwuj wizytę przez Booksy
                                </Link>
                            </li>
                            <li>
                                <Link to="https://www.instagram.com/hanna_terechowska?igsh=dXQxMjU0NG55azVo">
                                    <img src={instagramLogo} height={30} alt=""/>
                                </Link>
                            </li>
                            <li>
                                <Link to="https://maps.app.goo.gl/FoMT3fYuavta3f6ZA">
                                    <img src={googlePinLogo} height={30} alt=""/>
                                </Link>
                            </li>
                        </ul>
                    </div>
                )}
                
                { isMobile && (
                    <div id="mobileNavigationButton" onClick = { toggleMenu }>
                        <IoMenu />
                    </div>
                )}
            </nav>

            {isMobile && showMenu && (
                <div id = "mobileMenu">
                    <ul>
                        <li>
                            <NavLink onClick = { toggleMenu } to="/" className="link">O nas</NavLink>
                        </li>
                        <li>
                            <NavLink  onClick = { toggleMenu } to="/price" className="link">Cennik</NavLink>
                        </li>
                        <li>
                            <NavLink onClick = { toggleMenu } to="/contact" className="link">Kontakt</NavLink>
                        </li>
                        <li>
                            <div id = "mobileMenuFooter">
                                <div id = "mobileDivider"/>

                                <Link to="https://booksy.com/pl-pl/232551_honey_salon-kosmetyczny_13750_wroclaw">
                                    Nasze Booksy
                                </Link>

                                <div id = "mobileSocials">
                                    <Link id = "mobileInstagram" to="https://www.instagram.com/hanna_terechowska?igsh=dXQxMjU0NG55azVo">
                                        <img src={instagramLogo} height={30} alt=""/>
                                    </Link>
                    
                                    <Link to="https://maps.app.goo.gl/FoMT3fYuavta3f6ZA">
                                        <img src={googlePinLogo} height={30} alt=""/>
                                    </Link>
                                </div>

                            </div>
                        </li>
                    </ul>
                </div>
            )}
        </header>
    )
}


export default NavBar;