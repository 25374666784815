import React from "react";
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from "react-router-dom";
import NavBar from "../navigation/navBar";
import Home from "../home/home";
import Price from "../price/price";
import Contact from "../contact/contact";
import Footer from "../footer/footer"
import backgroundImage from "../resources/images/background.png"

function App() {
    return (
        <div style={{ backgroundImage: `url(${backgroundImage})` }}>
            <Router>
                <NavBar />
                <main>
                    <Routes>
                        <Route path="/" element={<Home/>} />
                        <Route path="/price" element={<Price/>} />
                        <Route path="/contact" element={<Contact/>} />
                    </Routes>
                </main>
                <Footer/>
            </Router>
        </div>
        
    )
};

export default App;
