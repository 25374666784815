import pinIcon from "../resources/images/pinWhite.png"
import phoneIcon from "../resources/images/phoneWhite.png"
import emailIcon from "../resources/images/emailWhite.png"
import timeIcon from "../resources/images/timeWhite.png"
import "./footer.css"

function Footer() {
    return (
    <div id = "footerConstainer">
        <div id="adressContainer">
            <img id = "icon" src= {pinIcon} height={25} alt=""></img>
            <div> 
                <p className = "footerText">Kaszubska 8/107, 4 piętro</p>
                <p className = "footerText">50-214 Wrocław</p>
            </div>
        </div>

        <div>
            <div id="telefonContainer"> 
                <img id = "icon" src= {phoneIcon} height={25} alt=""></img>
                <p className = "footerText">+48 731 659 700</p>
            </div> 

            <div id="emailContainer"> 
                <img id = "icon" src= {emailIcon} height={25} alt=""></img>
                <p className = "footerText">h.terechowska@gmail.com</p>
            </div> 
        </div>

        <div id="hoursContainer">
            <img id = "icon" src= {timeIcon} height={25} alt=""></img>
            <div> 
                <p className = "footerText">Godziny otwarcia</p>
                <p className = "footerText">PN - SB: 10:00 - 19:00</p>
            </div>
        </div>
    </div>
    )
}

export default Footer