
const price1 = {
    title: "Oczyszczanie wodorowe",
    elements: [ { title: "Twarz + Szyja + Dekolt", price: "300 zł" }, 
                {title: "Twarz", price: "200 zł" } ]
}

const price2 = {
    title: "Mezoterapia mikroigłowa",
    elements: [ { title: "Dekolt", price: "170 zł" }, 
                { title: "Twarz", price: "250 zł" },
                { title: "Szyja", price: "170 zł" },
                { title: "Trzy okolicy", price: "400 zł" },
                { title: "Dwie okolicy", price: "300 zł" },
            ]
}

const price3 = {
    title: "Mezoterapia igłowa",
    elements: [ { title: "Okolicy oczu", price: "250 zł" }, 
                { title: "Skóra głowy", price: "450 zł" },
                { title: "Twarz", price: "450 zł" },
                { title: "Twarz + Szyja", price: "550 zł" }
            ]
}

const price4 = {
    title: "Osocze bogatopłytkowe",
    elements: [ { title: "Szyja", price: "350 zł" }, 
                { title: "Skóra głowy", price: "500 zł" },
                { title: "Twarz", price: "750 zł" },
                { title: "Twarz + Szyja + Dekolt", price: "1000 zł" }
            ]
}

const price5 = {
    title: "Botoks",
    elements: [ { title: "Szyja", price: "350 zł" }, 
                { title: "Skóra głowy", price: "500 zł" },
                { title: "Twarz", price: "750 zł" },
                { title: "Twarz + Szyja + Dekolt", price: "1000 zł" }
            ]
}

const price6 = {
    title: "Wolumetria",
    elements: [ { title: "Hialuronidaza", price: "350 zł" }, 
                { title: "Kwas Hialuronowy - Usta", price: "500 zł" }
            ]
}

const price7 = {
    title: "Masaż Kobito",
    elements: [ { title: "Masaż Kobito", price: "250 zł" }]
}

const price8 = {
    title: "Oczyszczanie",
    elements: [ { title: "Oczyszczanie manualne", price: "100 zł" }, 
              { title: "Peeling kawitacyjny", price: "120 zł" },
              { title: "Peeling enzymatyczny", price: "120 zł" }
            ]
}


export const pricesList = [price1, price2, price3, price4, price5, price6, price7, price8]