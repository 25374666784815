import "./price.css"
import { pricesList } from "../models/pricesData"
import React, { useState } from 'react'
import arrowDown from "../resources/images/arrowDown.png"

function Price() {
    let column = []

    for (let i = 0; i < pricesList.length; i++) {
        column.push(<PriceRow data = { pricesList[i] }/>)
    }

    return (
        <div id = "priceContainer">
            { column }
        </div>
    )
}

function PriceRow(props: Props)  {
    let elements = []
    for (let i = 0; i < props.data.elements.length; i++) {
        elements.push(<PriceElement data = { props.data.elements[i] }/>)
    }
    const [toogle, setToggle] = useState(false)

    return (
        <div id ="priceElement"> 
            <button id = "titleButton" onClick = { () => setToggle(!toogle) } >
                <div id = "priceRow"> 
                    <h3> { props.data.title }</h3>
                    <img id = "arrow-icon" 
                        style = {{ transform: toogle ? "rotate(180deg)" : "rotate(0deg)" }} src= { arrowDown } alt=""/>
                </div>
            </button>

            <p> </p>

            { toogle && elements }
        
            <div id = "divider"/>
        </div>
    )
}


// Help functions
function PriceElement(props: Props) {
    return (
        <div id="elementRow">
            <p id = "priceTitle"> { props.data.title } </p>
            <h3> { props.data.price } </h3>
        </div>
        
    )
}



export default Price;